<template>
  <div class="table-checkbox-view">
    <!-- table 组件 -->
    <slot></slot>
    <!-- checkbox 组件 -->
    <a-checkbox
      v-if="isCheckedShow"
      :checked="isCheckedAll"
      :indeterminate="isIndeterminate"
      :disabled="isCheckedDisabled"
      class="table-checkbox"
      @change="onSelectAll">
      <!-- 自定义 checkbox 内容 -->
      <slot name="checkbox" :disabled="isCheckedDisabled">
        <a-button
          :disabled="isCheckedDisabled"
          style="margin-left: 10px;"
          @click="touchSubmit"
        >
          <!-- 自定义 checkbox-title 内容 -->
          <slot name="checkbox-title">批量处理</slot>
        </a-button>
      </slot>
    </a-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    // Table 数据源 Table - dataSource
    dataSource: {
      type: Array,
      required: true,
      default: () => []
    },
    // Table 选中项的 key 数组 Table - rowSelection - selectedRowKeys
    selectedRowKeys: {
      type: Array,
      required: true,
      default: () => []
    },
    // (可选) Table 选中项的 key 数组 Table - rowSelection - selectedRowKeys
    selectedRows: {
      type: Array,
      default: () => undefined
    },
    // (可选) 从数据源每个 item 中获取指定唯一标识字段 id，例如: item['id']
    sourceKey: {
      type: String,
      default: () => 'id'
    },
    // (可选) Table 分页器 Table - pagination，例如: { pageSize: 10, current: 1 } 如果使用该属性这两个字段必须存在
    pagination: {
      type: Object,
      default: () => undefined
    },
    // (可选) Table 是否在加载中 Table - loading
    isLoading: {
      type: Boolean,
      default: () => false
    },
    // (可选) 是否隐藏 checkbox 组件
    isHidden: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    // 监听数据源
    dataSource: {
      handler (newData) {
        // 是否显示 checkbox 组件
        if (this.isHidden) {
          this.isCheckedShow = !this.isHidden
        } else {
          this.isCheckedShow = Boolean(newData.length)
        }
        // 如果正在加载还得按照加载状态来
        if (this.isLoading) {
          this.isCheckedDisabled = true
        } else {
          this.isCheckedDisabled = !this.isCheckedShow
        }
        // 刷新分页器
        this.reloadPagination()
      },
      immediate: true,
      deep: true
    },
    // 监听选中项的 key 数组
    selectedRowKeys: {
      handler (newData) {
        // 刷新分页器
        this.reloadPagination()
      },
      immediate: true,
      deep: true
    },
    // 监听分页数据
    pagination: {
      handler (newData) {
        // 刷新分页器
        this.reloadPagination()
      },
      immediate: true,
      deep: true
    },
    // 是否正在加载数据
    isLoading: {
      handler (newData) {
        this.isCheckedDisabled = newData
      },
      immediate: true
    },
    // 是否隐藏 checkbox 组件
    isHidden: {
      handler (newData) {
        if (newData) {
          // 当设置隐藏的时候，直接强制隐藏
          this.isCheckedShow = !newData
        } else {
          // 当设置显示的时候，检查是否有数据源
          if (this.dataSource.length) {
            this.isCheckedShow = !newData
          }
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      // 是否显示 checkbox 组件UI
      isCheckedShow: false,
      // 是否有选择项
      isIndeterminate: false,
      // 是否选中全部
      isCheckedAll: false,
      // 是否禁用了点击
      isCheckedDisabled: false
    }
  },
  methods: {
    // 点击提交按钮
    touchSubmit () {
      this.$emit('submit')
    },
    // 全选按钮
    onSelectAll () {
      // 切换选中状态
      this.isCheckedAll = !this.isCheckedAll
      // 获取当前页面唯一索引值
      const { selectedRows } = this.getPageSelectedRowKeys()
      // 选择取消操作
      if (this.isCheckedAll) {
        // 选中
        selectedRows.forEach(item => {
          const id = item[this.sourceKey]
          const index = this.selectedRowKeys.indexOf(id)
          if (index === -1) {
            this.selectedRowKeys.push(id)
            if (this.selectedRows) { this.selectedRows.push(item) }
          }
        })
      } else {
        // 取消
        selectedRows.forEach(item => {
          const id = item[this.sourceKey]
          const index = this.selectedRowKeys.indexOf(id)
          if (index !== -1) {
            this.selectedRowKeys.splice(index, 1)
            if (this.selectedRows) { this.selectedRows.splice(index, 1) }
          }
        })
      }
      // 回调
      this.$emit('select', this.selectedRowKeys, this.selectedRows || [])
    },
    // 分页器刷新显示
    reloadPagination () {
      // 存在选中 && 数据源有值
      if (this.selectedRowKeys.length && this.dataSource.length) {
        // 获取当前页面唯一索引值
        const { selectedRowKeys } = this.getPageSelectedRowKeys()
        // 获得本次索引长度
        // const selectedRowKeysLength = selectedRowKeys.length
        // 通过移除判断是否有被选中或全选
        this.selectedRowKeys.forEach(item => {
          const index = selectedRowKeys.indexOf(item)
          if (index !== -1) {
            selectedRowKeys.splice(index, 1)
          }
        })
        // 没有分页器，直接使用数据源比较
        // this.isIndeterminate = (selectedRowKeys.length > 0 && selectedRowKeys.length < selectedRowKeysLength)
        this.isCheckedAll = selectedRowKeys.length === 0
      } else {
        // 不存在直接设置为不选中
        // this.isIndeterminate = false
        this.isCheckedAll = false
      }
    },
    // 获取当前页面唯一索引值
    getPageSelectedRowKeys () {
      // 索引范围内的数据源
      const selectedRows = []
      // 索引范围内的所有唯一值 this.sourceKey
      const selectedRowKeys = []
      // 数据源有数据
      if (this.dataSource.length) {
        // 一页多少数据
        var pageSize = 0
        // 当前第几页
        var current = 0
        // 有分页器
        if (this.pagination) {
          // 一页多少数据
          pageSize = this.pagination.pageSize || 0
          // 当前第几页
          current = this.pagination.current || 0
        } else {
          // 没有分页器
          pageSize = this.dataSource.length
        }
        // 开始索引
        var start = 0
        // 如果数据源大于一个数据，说明不是接口分页，是本地自动分页
        if (this.dataSource.length > pageSize) {
          start = Math.max(current - 1, 0) * pageSize
        }
        // 结束索引
        const end = Math.min(start + pageSize, this.dataSource.length)
        // 获取索引范围内的所有唯一值
        for (let index = start; index < end; index++) {
          const item = this.dataSource[index]
          if (!item.disabled) {
            selectedRows.push(item)
            const itemValue = item[this.sourceKey]
            selectedRowKeys.push(itemValue)
          }
        }
      }
      // 返回
      return { selectedRows: selectedRows, selectedRowKeys: selectedRowKeys }
    }
  }
}
</script>

<style scoped>
.table-checkbox-view {
  position: relative;
}
.table-checkbox {
  position: absolute;
  left: 8px;
  bottom: 16px;
}
</style>
